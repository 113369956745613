import { render, staticRenderFns } from "./projectcoordinators_invoices.vue?vue&type=template&id=52bc0e63&scoped=true&"
import script from "./projectcoordinators_invoices.vue?vue&type=script&lang=js&"
export * from "./projectcoordinators_invoices.vue?vue&type=script&lang=js&"
import style0 from "./projectcoordinators_invoices.vue?vue&type=style&index=0&id=52bc0e63&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52bc0e63",
  null
  
)

export default component.exports