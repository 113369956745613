<template>
  <div class="project-overview">
    <div
      v-if="
        typeof getActiveUserData !== 'undefined' &&
          Object.keys(getActiveUserData).length
      "
    >
      <adminvue-projects-invoices-vue
        v-if="(getActiveUserData.userRoles == 'admin') || (getActiveUserData.userRoles == 'subadmin')"
      ></adminvue-projects-invoices-vue>
      <projectcoordinators-invoices-vue
        v-else-if="getActiveUserData.userRoles == 'projectcoordinators'"
      ></projectcoordinators-invoices-vue>
      <salesteamleadvue-projects-invoices-vue
        v-else-if="(getActiveUserData.userRoles == 'salesteamlead') || (getActiveUserData.userRoles == 'salesmanager')"
      ></salesteamleadvue-projects-invoices-vue>
      <salesperson-projects-invoices-vue
        v-else-if="getActiveUserData.userRoles == 'salesperson'"
      ></salesperson-projects-invoices-vue>
      <marketingmanager-projects-invoices-vue
        v-else-if="getActiveUserData.userRoles == 'marketingmanager'"
      ></marketingmanager-projects-invoices-vue>
      <client-projects-invoices-vue
        v-else-if="getActiveUserData.userRoles == 'client'"
      ></client-projects-invoices-vue>
      <not-found v-else></not-found>
    </div>
    <div v-else class="white-row loader-route">
      <div class="lds-sub-ripple">
        <div></div>
        <div></div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import adminvue from "./admin_invoices";
import salesteamlead from "./salesteamlead_invoices";
import marketingmanager from "./marketing-manager-invoice";
import salespersonvue from "./salesperson_invoices";
import clientvue from "./client_invoices";
import noComponent from "../../../../components/noComponentFound";
import projectcoordinators from "./projectcoordinators_invoices.vue"

export default {
  components: {
    "adminvue-projects-invoices-vue": adminvue,
    "salesteamleadvue-projects-invoices-vue": salesteamlead,
    "marketingmanager-projects-invoices-vue": marketingmanager,
    "salesperson-projects-invoices-vue": salespersonvue,
    "client-projects-invoices-vue": clientvue,
    "not-found": noComponent,
    "projectcoordinators-invoices-vue" : projectcoordinators,
  },
  computed: {
    getActiveUserData() {
      return this.$store.state.auth;
    }
  }
};
</script>
